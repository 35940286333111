import { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [showOffline, setShowOffline] = useState("true");
  const [showImages, setShowImages] = useState("true");

  useEffect(() => {
    doStuff();
  }, []);

  const [feed, setFeed] = useState({
    content: [],
    pageable: {
      size: process.env.REACT_APP_PAGESIZE,
      page: 0,
      sort: [
        { field: "status", dir: "desc" },
        { field: "id", dir: "desc" },
      ],
    },
  });

  const getFeed = async () => {
    return new Promise((resolve, reject) => {
      const sorts = feed.pageable.sort
        .map((it) => it.field + "," + it.dir)
        .join("&sort=");

      const search = window.location.search;
      const params = new URLSearchParams(search);
      const values = params.get("pool");
      const size = params.get("size");
      const showOfflineParam = params.get("showoffline") ?? "true";
      const showImages = params.get("images") ?? "true";
      setShowOffline(showOfflineParam);
      setShowImages(showImages);
      const page = params.get("page") ?? 0;
      const sortValues = params.get("sort") ?? "";
      const poolsCon = values.split(",").join("&pool=");
      console.log(poolsCon);
      if (poolsCon == "") {
        return;
      }
      fetch(
        process.env.REACT_APP_API_URL +
          `/feed?pool=${poolsCon}&sort=${sortValues}&size=${size}&page=${page}`
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const delay = async () => {
    return new Promise((resolve) => setTimeout(resolve, 1000));
  };

  async function getFeedTOut() {
    const data = await getFeed();

    setFeed({
      content: data.content,
      pageable: { ...feed.pageable, size: data.size, page: data.page },
    });
  }

  const doStuff = async () => {
    await getFeedTOut().then(async (ok) => {
      await delay();
    });
    doStuff();
  };

  function getStatus(statusCode) {
    switch (statusCode) {
      case 0:
        return { textVal: "Offline", className: "offline" };
        break;
      case 1:
        return { textVal: "FREE", className: "available" };
        break;
      case 2:
        return { textVal: "BUSY", className: "busy" };
        break;
      default:
        return "";
    }
  }

  const agentPanels = feed.content.reverse().map((it) => {
    var status = getStatus(it.status);

    return (
      <div
        className={
          "col-xs-12 col-md-6 col-lg-4 col-xl-4" +
          (showOffline === "false" && it.status == "0" && " d-none")
        }
      >
        <div className="row agent-row agent-data">
          {showImages === "true" && (
            <div className="col col-6 col-sm-3 user-img-col">
              <img
                referrerPolicy="no-referrer"
                className="avatar"
                src={(it.imageUrl != "" && it.imageUrl) || "/images/user.png"}
                alt="user"
              />
            </div>
          )}
          <div
            className={
              "col " +
              (showImages === "false"
                ? "col-6 col-sm-6 col-md-6"
                : "col-6 col-sm-3 col-md-3")
            }
          >
            <div className="col-12 ">
              <div className="title-text">{it.displayName}</div>
            </div>
            <div className="col-12   pin-col">
              <div>PIN: {it.id}</div>
            </div>
          </div>
          <div
            className={
              "col " +
              (showImages === "false" ? "col-5 col-md-3" : "col-12") +
              " col-sm-3"
            }
          >
            <div className="col-auto">
              <div className={"status-box " + status.className}>
                {status.textVal}
              </div>
            </div>
          </div>
        </div>
        <div className="row line-row">
          <div className="line" />
        </div>
      </div>
    );
  });

  return (
    <div className="container-fluid">
      <div className="table">
        <div className="row">{agentPanels}</div>
      </div>
    </div>
  );
}

export default App;
